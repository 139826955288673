<template>
  <div id="title" class="sticky top-0 mt-12 bg-white pb-3">
    <div class="pb-3 pt-5 font-sans font-medium text-theme-1">
      {{ $t("pages.sell-a-profile.gallery.title", { bundle_name: bundleName }) }}
    </div>

    <div class="flex flex-row items-center justify-between rounded bg-neutral-100 md:max-w-[345px]">
      <button
        class="m-1 grow rounded py-2 font-normal leading-5 hover:bg-stone-200 active:bg-[#DADADA]"
        :class="{ selected: galleryToShow === 'EDITED' }"
        data-testid="after-edit"
        @click="showGallery('EDITED')"
      >
        <span>{{ $t("pages.sell-a-profile.preview-gallery.edited") }}</span>
      </button>

      <button
        class="m-1 grow rounded py-2 font-normal leading-5 hover:bg-stone-200 active:bg-[#DADADA]"
        :class="{ selected: galleryToShow === 'ORIGINAL' }"
        data-testid="before-edit"
        @click="showGallery('ORIGINAL')"
      >
        <span>{{ $t("pages.sell-a-profile.preview-gallery.originals") }}</span>
      </button>
    </div>
  </div>

  <div data-testid="gallery">
    <MasonryWall
      :items="gallery"
      :minColumns="2"
      :maxColumns="5"
      :columnWidth="300"
      :gap="8"
      :class="{ 'is-disabled': galleryToShow !== 'EDITED' }"
    >
      <template #default="{ item }">
        <div>
          <img :src="item" class="w-full" />
        </div>
      </template>
    </MasonryWall>
  </div>

  <div data-testid="original-gallery">
    <MasonryWall
      :items="originalGallery"
      :minColumns="2"
      :maxColumns="5"
      :columnWidth="300"
      :gap="8"
      :class="{ 'is-disabled': galleryToShow !== 'ORIGINAL' }"
    >
      <template #default="{ item }">
        <div>
          <img :src="item" class="w-full" />
        </div>
      </template>
    </MasonryWall>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import MasonryWall from "@yeger/vue-masonry-wall";
import { useMagicKeys } from "@vueuse/core";

type GalleryType = "EDITED" | "ORIGINAL";

const galleryToShow: Ref<GalleryType> = ref<GalleryType>("EDITED");

defineProps<{
  bundleName: string;
  gallery: string[];
  originalGallery: string[];
}>();

const showGallery = (type: GalleryType) => {
  galleryToShow.value = type;
};

useMagicKeys({
  onEventFired: (e: KeyboardEvent) => {
    if (e.type !== "keydown") return;

    togglePreview(e);
  },
});

const togglePreview = (e: KeyboardEvent) => {
  if (e.key === "ArrowLeft") {
    galleryToShow.value = "EDITED";
  } else if (e.key === "ArrowRight") {
    galleryToShow.value = "ORIGINAL";
  }
};
</script>

<style scoped lang="scss">
.is-disabled {
  @apply h-0 w-0 opacity-0;
  img {
    @apply h-0;
  }
}

button {
  &.selected {
    @apply bg-white shadow;
  }
}
</style>
